import React, { Component } from 'react';

class Portfolio extends Component {
  render() {

    if (this.props.data) {
      var projects = this.props.data.projects.map(function (projects) {
        var projectImage = 'images/portfolio/' + projects.image;
        return <div key={projects.title} className="columns portfolio-item" style={{ width: '100%', height: '100%' }}>
          <div className="item-wrap">
            <a target="_blank" rel="noopener noreferrer" href={projects.url} title={projects.title}>
              <img alt={projects.title} src={projectImage} />
              <div className="overlay">
                <div className="portfolio-item-meta">
                  <h5>{projects.title}</h5>
                  <p>{projects.category}</p>
                </div>
              </div>
              <div className="link-icon"><i className="fa fa-link"></i></div>
            </a>
          </div>
        </div>
      })
    }

    return (
      <section id="portfolio">

        <div className="row">

          <div className="twelve columns collapsed" style={{ marginLeft:10, marginRight:10, textAlign:'center' }}>

            <img style={{ width: 96, height: 96,  }} src={'images/portfolio/KaamLogo.png'} />
            <h3>Kaam</h3>
            <h1>App to bridge blue-collar job market</h1>


            <p>
              An application that makes finding Blue-collar jobs easier. Finding a Cook or Maid is a tedious task in India.
              It is usually through word of mouth. This app has a simple interface to enable even the lower end users to get what they are looking for. It currently supports more than 100 categories of blue-collar jobs.
            </p>
            <p>
              During the COVID-19 crisis, the unemployment rate in India almost doubled in April from 7.6% in March.
              This got me researching the problem. I figured most of the unemployed were blue-collar workers. Though most of these people have access to smartphones, they are not used to navigating through complex UI. There are no apps with straightforward navigation to help the less sophisticated users (which accounts for majority of Indian population). There are more than 650M smartphone users in India (almost double the population of US). But the penetration is less than 50%  as of 2020. Also, there is no good platform for connecting Job-seekers with the employers in this segment.
              So I decided to build one.
            </p>
            <p>
              After 12 weekends of work, I published the app to google play. It was a great learning experience. It helped me improve my knowledge in React-Native, Rust, GraphQl.
              The initial plan was to get a designer for ui/ux. But I decided to try my hand in that as well. I am currently working on translating this to 5 Indian Languages.
            </p>
            <div className="kaamTech">
              <p style={{ color: '#43c6ac', fontWeight: 'bold' }}>The App is built in React-Native with TypeScript. Backend is primarily in Node with Apollo Serve, Google Maps Api,
              GraphQL interface, PostgreSQL DB.
            </p>
              <p>Link to <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.kaamapplication">Google Play Store</a></p>
            </div>


            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
              {projects}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
