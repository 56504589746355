import React, { Component } from 'react';

class Resume extends Component {
  render() {

    if (this.props.data) {
      var skillmessage = this.props.data.skillmessage;
      var education = this.props.data.education.map(function (education) {
        return <div key={education.school}><h3>{education.school}</h3>
          <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
          <p className="descPara">{education.description}<a href={education.descriptionUrl}  style={{textDecorationLine: 'underline'}}>{education.descriptionUa}</a></p>
          <p className="descPara">{education.description1}</p>
          <p></p>
          </div>
      })
      var work = this.props.data.work.map(function (work) {
        return <div key={work.company}><h3>{work.company}</h3>
          <p className="info">{work.title}<span>&bull;</span> <em className="date">{work.years}</em></p>
          <p className="descPara">{work.description}</p>
          <p className="descPara">{work.description1}</p>
          <p className="descPara">{work.description2}</p>
          <p className="descPara"><a href={work.description3Url} style={{textDecorationLine: 'underline'}}>{work.description3}</a></p>
          <p className="descPara">
            {work.description3p1}<a href={work.description3p2aurl} style={{textDecorationLine: 'underline'}}>{work.description3p2a}</a>
            {work.description3p3}<a href={work.description3p4aurl} style={{textDecorationLine: 'underline'}}>{work.description3p4a}</a>
            {work.description3p5}<a href={work.description3p6aurl} style={{textDecorationLine: 'underline'}}>{work.description3p6a}</a>
          </p>
          <p className="descTech">{work.tech}</p>
          <p></p>
        </div>
      })
      var skills = this.props.data.skills.map(function (skills) {
        var className = 'bar-expand ' + skills.name.toLowerCase();
        return <li key={skills.name}><span style={{ width: skills.level }} className={className}></span><em>{skills.name}</em></li>
      })
    }

    return (
      <section id="resume">
        

        <div className="row work">
          
          <div className="three columns header-col">
            <h1><span>Work Experience</span></h1>
          </div>
          

          <div className="nine columns main-col">
            {work}
          </div>

          {/* <div className="nine columns main-col download" style={{}}>
                  <p>
                     <a href={"./Resume.pdf"} download className="button"  style={{backgroundColor:'#444', color:'white'}}><i className="fa fa-download" style={{marginRight:5}}></i>Download Resume</a>
                  </p>
           </div> */}
        </div>

 

        <div className="row education">
          <div className="three columns header-col">
            <h1><span>Education</span></h1>
          </div>

          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">
                {education}
              </div>
            </div>
          </div>
          
        </div>





        <div className="row skill">

          <div className="three columns header-col">
            <h1><span>Skills</span></h1>
          </div>

          <div className="nine columns main-col">

            <p>{skillmessage}
            </p>

            <div className="bars">
              <ul className="skills">
                {skills}
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Resume;
